<template>
    <div
        v-if="uploadedFiles.length > 0"
        class="file-uploader__box file-uploader__box_uploaded"
    >
        <div class="attachments-table">
            <div class="attachments-table__head">
                <div class="attachments-table__row">
                    <div
                        class="attachments-table__cell"
                        :class="{
                            'attachments-table__cell_sort-up':
                                sorting.sort_by === 'filename' && sorting.sort_order === 'desc',
                            'attachments-table__cell_sort-down':
                                sorting.sort_by === 'filename' && sorting.sort_order === 'asc'
                        }"
                        @click.prevent="sortList('filename', sorting.sort_order === 'asc' ? 'desc' : 'asc')"
                    >
                        <span>Name</span>
                    </div>
                    <div
                        class="attachments-table__cell"
                        :class="{
                            'attachments-table__cell_sort-up':
                                sorting.sort_by === 'created_at' &&
                                sorting.sort_order === 'desc',
                            'attachments-table__cell_sort-down':
                                sorting.sort_by === 'created_at' && sorting.sort_order === 'asc'
                        }"
                        @click.prevent="sortList('created_at', sorting.sort_order === 'asc' ? 'desc' : 'asc')"
                    >
                        <span>Date</span>
                    </div>
                    <div
                        v-if="showOwner"
                        class="attachments-table__cell attachments-table__cell_avatar"
                        :class="{
                            'attachments-table__cell_sort-up':
                                sorting.sort_by === 'owner_model' && sorting.sort_order === 'desc',
                            'attachments-table__cell_sort-down':
                                sorting.sort_by === 'owner_model' && sorting.sort_order === 'asc'
                        }"
                        @click.prevent="sortList('owner_model', sorting.sort_order === 'asc' ? 'desc' : 'asc')"
                    >
                        <span>Owner</span>
                    </div>
                    <div
                        v-if="showType && hasFileType"
                        class="attachments-table__cell attachments-table__cell_type"
                        :class="{
                            'attachments-table__cell_sort-up':
                                sorting.sort_by === 'type_description' && sorting.sort_order === 'desc',
                            'attachments-table__cell_sort-down':
                                sorting.sort_by === 'type_description' && sorting.sort_order === 'asc'
                        }"
                        @click.prevent="sortList('type_description', sorting.sort_order === 'asc' ? 'desc' : 'asc')"
                    >
                        <span>Type</span>
                    </div>
                    <div
                        class="attachments-table__cell attachments-table__cell_size"
                        :class="{
                            'attachments-table__cell_sort-up':
                                sorting.sort_by === 'filesize' && sorting.sort_order === 'desc',
                            'attachments-table__cell_sort-down':
                                sorting.sort_by === 'filesize' && sorting.sort_order === 'asc'
                        }"
                        @click.prevent="sortList('filesize', sorting.sort_order === 'asc' ? 'desc' : 'asc')"
                    >
                        <span>Size</span>
                    </div>
                    <div class="attachments-table__cell attachments-table__cell_actions">
                        <span>Actions</span>
                    </div>
                </div>
            </div>
            <div class="attachments-table__body">
                <transition-group name="file">
                    <div
                        v-for="(file) in uploadedFiles"
                        :key="file.file_id"
                        class="attachments-table__row"
                    >
                        <div class="attachments-table__cell">
                            <div class="loaded-file__preview">
                                <span class="loaded-file__preview-format">
                                    {{ getFileExtension(file.filename) }}
                                </span>
                            </div>
                            <span
                                class="loaded-file__name"
                                style="overflow: hidden"
                                :class="setElementClass(file)"
                            >
                                <span>
                                    {{ file.filename }}
                                </span>
                                <!-- <span
                                    v-if="checkFinalFile(file)"
                                    :class="{'loaded-file__name-final': checkFinalFile(file)}"
                                >
                                    Final document
                                </span> -->
                            </span>
                        </div>
                        <div class="attachments-table__cell">
                            <span
                                v-if="file.created_at"
                                style="display:flex; align-items: center"
                            >
                                {{ file.created_at | moment_from }} ago<br>
                                <span
                                    class="loaded-file__date-short-text tooltip-zoom"
                                    :data-tooltip-attribute="file.created_at | moment_short_text"
                                >
                                    i
                                </span>
                            </span>
                        </div>
                        <template
                            v-if="showOwner"
                        >
                            <div class="attachments-table__cell attachments-table__cell_avatar">
                                <template v-if="!!file.author">
                                    <Avatar
                                        :avatar-url="file.author.avatar_url"
                                        :avatar-fallback="file.author.avatar_fallback"
                                        :firstname="file.author.firstname"
                                        :lastname="file.author.lastname"
                                        :user="file.author.user"
                                        :class-name="'attachments-avatar'"
                                    />
                                    <span class="loaded-file__author-details--name">
                                        <span v-if="file.owner_model === 'Writer'"> You </span>
                                        <span v-else>
                                            <span class="loaded-file__author-details--staff">{{ file.author.type }}</span>
                                            {{ `${file.author.firstname}` }}
                                            <template v-if="!!file.author.lastname">
                                                {{ `${!!file.author.lastname && file.author.lastname.charAt(0)}` }}
                                            </template>
                                        </span>
                                    </span>
                                </template>
                            </div>
                        </template>
                        <!-- filetype -->
                        <div
                            v-if="showType && file.type_description"
                            class="attachments-table__cell attachments-table__cell_type"
                        >
                            <span class="loaded-file__type">
                                {{ file.type_description }}
                            </span>
                        </div>
                        <!-- filetype -->
                        <!-- filesize -->
                        <div class="attachments-table__cell attachments-table__cell_size">
                            <span class="loaded-file__size">
                                {{ Number(file.filesize) | file_size }}
                            </span>
                        </div>
                        <!-- filesize -->
                        <!-- actions -->
                        <div class="attachments-table__cell attachments-table__cell_actions">
                            <!-- download file -->
                            <!-- main file -->
                            <a
                                class="attachments-table__action attachments-table__action_download"
                                :href="file.url"
                                download
                            />
                            <!-- main file -->
                            <!-- delete file -->
                            <a
                                v-if="!!file.author && file.owner_model === 'Writer'"
                                class="attachments-table__action attachments-table__action_delete"
                                title="Delete File"
                                @click.prevent="deleteFile(file)"
                            />
                        <!-- delete file -->
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        <delete-file-modal
            :id="deletedFile.file_id"
            :ref="`delete-modal`"
            :url="deleteUrl"
            @success="confirmDelete"
        />
    </div>
</template>

<script>
import Avatar from '@/components/common/Avatar.vue';
import DeleteFileModal from '@/components/modals/DeleteFileModal';
import filtersMixin from '@/mixins/filtersMixin';

export default {
    name: 'AttachmentsTable',
    components: {
        Avatar,
        DeleteFileModal
    },
    mixins: [filtersMixin],
    props: {
        uploadedFiles: {
            type: Array,
            required: true
        },
        uploadedFilesOwn: {
            type: Array,
            default: () => ([])
        },
        deleteUrl: {
            type: String,
            required: false,
            default: ''
        },
        unreadFilesCount: {
            type: Number,
            required: false,
            default: 0
        },
        showOwner: {
            type: Boolean,
            required: false,
            default: true
        },
        showType: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            sorting: {
                sort_by: 'created_at',
                sort_order: 'asc'
            },
            deletedFile: {},
            newFiles: []
        };
    },
    computed: {
        hasFileType() {
            return this.uploadedFiles.some((file) => file.type_description)
        }
    },
    watch: {
        'uploadedFiles.length': function (length) {
            if (length > 0) {
                this.sortList(this.sorting.sort_by)
            }
        }
    },
    created() {
        this.uploadedFiles.forEach((file, index) => {
            if (index >= this.uploadedFiles.length - this.unreadFilesCount) {
                this.newFiles.push(file.file_id)
            }
        })
        this.sortList(this.sorting.sort_by)
    },
    methods: {
        // checkFinalFile(file) {
        //     return file?.complete_status && file.complete_status === 1;
        // },
        sortList(sort_by, order) {
            this.sorting.sort_by = sort_by;
            const sort_order = order ?? this.sorting.sort_order;
            this.sorting.sort_order = sort_order;
            if (this.sorting.sort_by === 'filesize') {
                this.uploadedFiles = this.uploadedFiles?.sort((a, b) => {
                    const varA = typeof a[this.sorting.sort_by] === 'string' ? a[this.sorting.sort_by].toUpperCase() : a[this.sorting.sort_by];
                    const varB = typeof b[this.sorting.sort_by] === 'string' ? b[this.sorting.sort_by].toUpperCase() : b[this.sorting.sort_by];
                    return this.sorting.sort_order === 'asc' ? varA - varB : varB - varA;
                }) || [];
            } else {
                this.uploadedFiles = this.uploadedFiles?.sort((a, b) => {
                    const varA = typeof a[this.sorting.sort_by] === 'string' ? a[this.sorting.sort_by].toUpperCase() : a[this.sorting.sort_by];
                    const varB = typeof b[this.sorting.sort_by] === 'string' ? b[this.sorting.sort_by].toUpperCase() : b[this.sorting.sort_by];
                    let comparison = 0;
                    if (varA > varB) {
                        comparison = 1;
                    } else if (varA < varB) {
                        comparison = -1;
                    }
                    return this.sorting.sort_order === 'asc' ? comparison * -1 : comparison;
                }) || [];
            }
        },
        getFileExtension(filename) {
            const data = filename.split('.');
            if (data.length) return data[data.length - 1];
            return false;
        },
        deleteFile(file) {
            this.deletedFile = { ...file }
            this.$refs['delete-modal'].open();
        },
        confirmDelete() {
            this.$emit('onDelete', this.deletedFile.file_id);
        },
        // checkIsNew(file) {
        //     return { new_file: this.newFiles.some((file_id) => file_id === file.file_id) || this.uploadedFilesOwn.some((item) => item.file_id === file.file_id) }
        // },
        setElementClass(file) {
            return this.newFiles.some((file_id) => file_id === file.file_id) || this.uploadedFilesOwn.some((item) => item.file_id === file.file_id)
                ? 'new_file'
                : ''
        }
    }
};
</script>

<style scoped>
    .file-enter-active,
    .file-leave-active {
        transition: all 0.9s ease;
    }
    .file-enter-from,
    .file-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .loaded-file__date-short-text{
        border: 1px solid #1f2939;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.1s ease-in;
        margin: 0 5px;
        cursor: pointer;
    }
</style>
