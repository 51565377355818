export default {
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        open() {
            this.dialog = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        close() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
