/* eslint-disable  */
import Api from '@/helpers/api/index.js'

export const deleteFile = async (id, url) => await Api.delete(`${url}?id=${id}`)

export const makeAsReadFiles = async (orderid) => await Api.put('/api/file/additional/mark-as-read', { orderid })

export const uploadFiles = async ({ url='/api/file/additional/upload', formData, callback }) => {
    const { data } = await Api.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: callback
    })
    return data
}

export const getFileApplicantTypes = async () => {
    const { data } = await Api.get('/api/file/applicant/list-types')
    return data
}
