<template>
    <div class="image_progress">
        <svg
            :height="60"
            :width="60"
        >
            <circle
                stroke="grey"
                stroke-dasharray="326.7256359733385 326.7256359733385"
                style="stroke-dashoffset: 0;"
                stroke-width="4"
                fill="transparent"
                :r="normalizedRadius"
                :cx="30"
                :cy="30"
            />
            <circle
                stroke="#1161E0"
                :stroke-dasharray="circumference + ' ' + circumference"
                :style="{ strokeDashoffset: strokeDashoffset }"
                stroke-width="4"
                fill="transparent"
                :r="normalizedRadius"
                :cx="30"
                :cy="30"
            />
            <text
                x="50%"
                y="50%"
                dominant-baseline="middle"
                text-anchor="middle"
            >
                {{ progress }}%
            </text>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        progress: Number
    },
    data() {
        return {
            normalizedRadius: 26,
            circumference: 26 * 2 * Math.PI
        };
    },
    computed: {
        strokeDashoffset() {
            // eslint-disable-next-line no-mixed-operators
            const result = this.circumference - this.progress / 100 * this.circumference
            return result
        }
    }
}
</script>
<style scoped>
    circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
    svg{
        font-size: 12px;
    }
</style>
