<template lang="html">
    <div
        class="file-uploader__box file-uploader__box_upload"
        :class="{ dragging: isDragging, error: uploadError, loading: files.length }"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover.prevent
        @drop="onDrop"
    >
        <div
            v-show="!files.length"
            class="file-uploader__dragzone"
        >
            <div
                v-if="!uploadError"
                class="file-uploader__dragzone-text"
            >
                Drag files here
                <p>{{ description }}</p>
            </div>
            <p
                v-else
                class="file-uploader__dragzone-error"
            >
                <span class="file-uploader__dragzone-icon">
                    <InformationOutline />
                </span>
                <span class="file-uploader__dragzone-info">
                    <span>{{ fileName }}</span>
                    <span>Error. Try to drag new file.</span>
                </span>
            </p>
            <div class="file-uploader__file-input">
                <input
                    id="file"
                    ref="input"
                    type="file"
                    :multiple="useMultiple"
                    @change="onInputChange"
                >
            </div>
        </div>

        <div
            v-show="files.length"
            class="file-uploader__file-loading"
        >
            <circle-progress
                v-if="uploadProgressValue > 0"
                :progress="uploadProgressValue"
            />
            <span>{{ fileName }}</span>
        </div>
    </div>
</template>

<script>
// import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus'

// Icon
import InformationOutline from 'mdi-vue/InformationOutline';
import CircleProgress from '@/components/CircleProgress.vue';

import { uploadFiles } from '@/services/files/filesService';

export default {
    name: 'FileUploader',
    components: {
        InformationOutline,
        CircleProgress
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        isFinal: {
            type: Boolean,
            default: false
        },
        typeIsRequired: {
            type: Boolean,
            default: false
        },
        type: {
            type: Number
        },
        maxFileSize: {
            type: Number
        },
        useMultiple: {
            type: Boolean,
            default: false
        },
        loadingUrl: {
            type: String,
            required: true
        }
    },
    data: () => ({
        isDragging: false,
        dragCount: 0,
        files: [],
        file: [],
        inProgress: false,
        uploadProgressValue: 0,
        uploadError: false,
        fileName: '',
        description: 'The following file formats are supported: JPG, JPEG, PNG, TIFF, BMP, PDF, DOCX, DOC, XLS'
    }),
    mounted() {
        eventBus.$on('finalizeFile', () => {
            this.$refs.input.click()
        })
    },
    beforeDestroy() {
        eventBus.$off('finalizeFile')
    },
    methods: {
        onDragEnter(e) {
            e.preventDefault();
            this.dragCount += 1;
            this.isDragging = true;
        },
        onDragLeave(e) {
            e.preventDefault();

            this.dragCount -= 1;
            if (this.dragCount <= 0) {
                this.isDragging = false;
            }
        },
        onDrop(e) {
            // this.fileExist(e)
            if (this.fileError) {
                this.$emit('error')
                this.deleteFile()
                e.preventDefault();
                e.stopPropagation();
                return
            }
            e.preventDefault();
            e.stopPropagation();

            this.isDragging = false;

            const { files } = e.dataTransfer;

            if (!this.checkFileSize()) return
            Array.from(files).forEach((file) => this.addUploadFiles(file));
            this.upload();
        },
        addUploadFiles(file) {
            this.files.push(file);
            if (!this.checkFileSize()) return
            // const img = new File(this.files, '');
            const reader = new FileReader();
            reader.onload = (e) => this.file.push(e.target.result);
            reader.readAsDataURL(file);
        },
        onInputChange(e) {
            // this.fileExist(e)
            if (this.fileError) {
                this.$emit('error')
                this.deleteFile()
                return
            }
            const { files } = e.target;
            Array.from(files).forEach((file) => this.addUploadFiles(file));
            if (!this.checkFileSize()) return
            this.upload();
        },
        checkFileSize() {
            const calculateFileSize = this.files.reduce((acc, curr) => {
                acc += (curr.size / 1024 / 1024)
                return acc
            }, 0)
            if (calculateFileSize > this.maxFileSize) {
                eventBus.$emit('showSnackBar', `The file cannot be larger than ${this.maxFileSize}MB`, 'error');
                this.files = []
                this.file = []
                this.inProgress = false
                return false
            }
            return true
        },
        upload() {
            if (this.typeIsRequired && !this.type) {
                this.file = [];
                this.files = [];
                this.$emit('error')
                this.deleteFile()
                return
            }
            this.inProgress = true;
            const formData = new FormData();
            this.fileName = this.files[0].name
            this.files.forEach((file) => {
                formData.append(`uploaded[${file.name}]`, file)
            });
            if (this.id) {
                formData.append('orderid', this.id)
            }
            if (this.type) {
                formData.append('type', this.type)
            }
            uploadFiles({ url: this.loadingUrl, formData, callback: this.onUploadProgress })
                .then((response) => {
                    eventBus.$emit('showSnackBar', 'The file has been saved', 'success');
                    this.$emit('success', response)
                    this.file = [];
                    this.files = [];
                    this.deleteFile()
                })
                .catch((error) => {
                    this.uploadError = true;
                    setTimeout(() => { this.uploadError = false }, 4000)
                    this.file = [];
                    this.files = [];
                    eventBus.$emit('showSnackBar', error, 'error');
                    this.$emit('error')
                    this.deleteFile()
                })
                .then(() => {
                    this.inProgress = false;
                })
        },
        onUploadProgress(progressEvent) {
            this.uploadProgressValue = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        },
        deleteFile() {
            this.$refs['input'].value = null
        }
    }
}
</script>

<style lang='scss'>
    .image_progress{
        position: absolute;
        top: 50%;
        transform: translateX(20px) translateY(-50%);
        background: #fff;
        padding: 5px;
        border-radius: 4px;
    }
    .file-uploader__box_upload{
        margin: 20px 0;
    }
</style>
